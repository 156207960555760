import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  // 修改原型对象中的push方法，replace方法就把VueRouter.prototype.push的push换成replace即可
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/core-products',
    component: () => import('@/views/head-tail.vue'),
    children: [
      {
        name: 'core-products',
        path: '/core-products',
        component: () => import('@/views/content/core-products.vue')
      },
      {
        path: '/home',
        component: () => import('@/views/contact-us.vue')
      },
      {
        name: 'emergency',
        path: '/emergency',
        component: () => import('@/views/content/emergency.vue')
      },
      {
        name: 'environment',
        path: '/environment',
        component: () => import('@/views/content/environment.vue')
      },
      {
        name: 'public-security',
        path: '/public-security',
        component: () => import('@/views/content/public-security.vue')
      },
      {
        name: 'fire-control',
        path: '/fire-control',
        component: () => import('@/views/content/fire-control.vue')
      },
      {
        name: 'news-info',
        path: '/news-info',
        component: () => import('@/views/content/news-info.vue')
      },
      {
        name: 'news-detail',
        path: '/news-detail',
        component: () => import('@/views/content/news-detail.vue')
      },
      {
        name: 'salute-hero',
        path: '/salute-hero',
        component: () => import('@/views/content/salute-hero.vue')
      },
      {
        name: 'martyrs',
        path: '/martyrs',
        component: () => import('@/views/content/martyrs.vue')
      },
      {
        name: 'hero-details',
        path: '/hero-details',
        component: () => import('@/views/content/hero-details.vue')
      },
      {
        name: 'product-performance',
        path: '/product-performance',
        component: () => import('@/views/content/product-performance.vue')
      },
      {
        name: 'location-system-page',
        path: '/location-system-page',
        component: () => import('@/views/content/location-system-page.vue')
      }

    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
